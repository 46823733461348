import {useI18n} from "common/hooks/useI18n";
import {useTailwindViewport} from "common/hooks/useTailwindViewport";
import {Step} from "features/companies/vehicleListings/add-listing/mock";

import {FleetOwnerRegistrationService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Button, Typography} from "@bolteu/kalep-react";

interface Props {
    steps: Step[];
    onPublishListing: () => void;
    onPrevStep: () => void;
}

const WizardReviewContent = ({steps, onPublishListing, onPrevStep}: Props) => {
    const {i18n} = useI18n();
    const viewport = useTailwindViewport();
    const isMobileView = viewport === "sm";

    const getFieldContent = (field: FleetOwnerRegistrationService.FormField) => {
        const {type} = field;

        switch (type) {
            case FleetOwnerRegistrationService.FormFieldType.FILEUPLOAD:
                return <div>Test File Upload</div>; // TODO Implement File Upload
            case FleetOwnerRegistrationService.FormFieldType.COMBOBOX: {
                if (field.multi) {
                    const values = field.options
                        .filter((option) => field.values?.includes(option.value))
                        .map((option) => option.label);

                    return (
                        <div>
                            <Typography variant="title-primary" fontSize="text-xl">
                                {field.label}
                            </Typography>
                            <ul className="mt-3 list-disc pl-5">
                                {values.map((value) => (
                                    <li key={value}>{value}</li>
                                ))}
                            </ul>
                        </div>
                    );
                }

                const value = field.options.find((option) => option.value === field.value)?.label || "";

                return (
                    <div>
                        <Typography variant="title-primary" fontSize="text-xl">
                            {field.label}
                        </Typography>
                        <p className="mt-2">{value}</p>
                    </div>
                );
            }
            case FleetOwnerRegistrationService.FormFieldType.TEXT:
                return <div>Test File Upload</div>; // TODO Implement File Upload
            default:
                return <></>;
        }
    };

    const getStepContent = (step: Step) => {
        const {fields} = step;
        return (
            <div key={step.id}>
                <Typography variant="title-primary" fontSize="text-2xl">
                    {step.title}
                </Typography>
                <div className="mt-3 flex flex-col gap-3">
                    {fields.map((field) => (
                        <div key={field.name}>{getFieldContent(field)}</div>
                    ))}
                </div>
            </div>
        );
    };

    return (
        <div>
            <div className="mb-6 flex flex-col gap-6">
                <Typography variant="title-primary" fontSize="text-3xl">
                    {i18n("auth.app.fleet.vehicle_marketplace.listings.add.review")}
                </Typography>
            </div>
            <div className="flex max-w-lg flex-col gap-6">{steps.map(getStepContent)}</div>
            <div className="mt-8 flex justify-between">
                {isMobileView && (
                    <Button variant="secondary" onClick={onPrevStep}>
                        {i18n("common.back")}
                    </Button>
                )}
                <Button type="submit" onClick={onPublishListing}>
                    {i18n("auth.app.fleet.vehicle_marketplace.listings.add.publish")}
                </Button>
            </div>
        </div>
    );
};

export default WizardReviewContent;
