import {useCallback, useMemo} from "react";

import {FleetOwnerRegistrationService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {FilterOptionsState} from "@bolteu/kalep-form-react/build/utils/MUIUtils";
import {ListItemLayout, OptionProps, SelectOption} from "@bolteu/kalep-react";

import {CustomOption} from "../Combobox";
import {Icon} from "../Icon";

export const getComboboxOptions = (options: FleetOwnerRegistrationService.ComboBoxOption[]): CustomOption[] =>
    options.map(({value, label: optionLabel, appearance, icon}) => {
        return {
            value,
            title: optionLabel,
            appearance,
            icon,
        };
    });

export const useComboBox = (options: FleetOwnerRegistrationService.ComboBoxOption[], isFreeForm: boolean) => {
    const autocompleteFieldOptions: CustomOption[] = useMemo(() => getComboboxOptions(options), [options]);

    const hasHeaderInactiveOption = useMemo(
        () =>
            autocompleteFieldOptions.some(
                (o) => o.appearance === FleetOwnerRegistrationService.ComboBoxAppearance.HEADER_INACTIVE,
            ),
        [autocompleteFieldOptions],
    );

    const getOptionLabel = useCallback(
        (option: SelectOption | null): string => {
            if (!option || (!isFreeForm && (!option?.value || !option?.title))) {
                return "";
            }

            if (isFreeForm && (option.isCustom || (option.title === undefined && option.value))) {
                return String(option.value ?? "");
            }

            const autocompleteFieldOption = autocompleteFieldOptions.find((x) => x.value === option.value);
            const optionTitle = autocompleteFieldOption?.title as undefined | string;

            let optionLabel: string | number | null | undefined;
            switch (autocompleteFieldOption?.appearance) {
                case FleetOwnerRegistrationService.ComboBoxAppearance.HEADER_INACTIVE:
                    optionLabel = optionTitle;
                    break;
                case FleetOwnerRegistrationService.ComboBoxAppearance.COLUMN_SPLIT:
                    optionLabel = autocompleteFieldOption.value;
                    break;
                default:
                    optionLabel = optionTitle;
                    break;
            }

            return String(optionLabel);
        },
        [autocompleteFieldOptions, isFreeForm],
    );

    const filterOptions = useCallback(
        (o: SelectOption[], state: FilterOptionsState<SelectOption>) => {
            // Taken from default filterOptions implementation in Kalep
            const stripDiacritics = (string: string) => {
                return typeof string.normalize !== "undefined"
                    ? string.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                    : string;
            };
            const normalizedSearchTerm = stripDiacritics(state.inputValue).toLowerCase();
            const filtered = o.filter(
                (option) => state.getOptionLabel(option).toLowerCase().indexOf(normalizedSearchTerm) >= 0,
            );
            if (filtered.length === 0 && isFreeForm && state.inputValue) {
                filtered.push({
                    value: state.inputValue,
                    title: state.inputValue,
                    isCustom: true,
                });
            }
            return filtered;
        },
        [isFreeForm],
    );

    const renderOption = useCallback(
        ({key, option, onClick, highlighted, selected, size}: OptionProps) => {
            const customOption: CustomOption = option as CustomOption;
            const {title, icon, appearance, value} = customOption;

            let primaryText;
            let renderStartSlot;
            let renderEndSlot;
            switch (appearance) {
                case FleetOwnerRegistrationService.ComboBoxAppearance.HEADER_INACTIVE:
                    primaryText = title;
                    renderStartSlot = () => (icon ? <Icon config={icon} /> : null);
                    break;
                case FleetOwnerRegistrationService.ComboBoxAppearance.COLUMN_SPLIT:
                    primaryText = title;
                    renderStartSlot = () => (icon ? <Icon config={icon} /> : null);
                    renderEndSlot = () => <div>{value}</div>;
                    break;
                default:
                    primaryText = hasHeaderInactiveOption ? (
                        <span className="ml-10">{title ?? value}</span>
                    ) : (
                        title ?? value
                    );
                    break;
            }

            const shouldDisableSelection =
                appearance && appearance === FleetOwnerRegistrationService.ComboBoxAppearance.HEADER_INACTIVE;

            return (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events -- Currently there is a strict typing in Kalep that you can only use <li>
                <li key={key} onClick={shouldDisableSelection ? undefined : onClick}>
                    <ListItemLayout
                        highlighted={highlighted}
                        selected={selected}
                        primary={primaryText}
                        variant={size === "sm" ? "sm" : "base"}
                        renderStartSlot={renderStartSlot}
                        renderEndSlot={renderEndSlot}
                    />
                </li>
            );
        },
        [hasHeaderInactiveOption],
    );

    return {
        autocompleteFieldOptions,
        getOptionLabel,
        filterOptions,
        renderOption,
    };
};
