import {useContext, useMemo} from "react";
import {useNavigate} from "react-router-dom";

import CashlessIcon from "assets/icons/cashless.svg?react";
import FilledCircle from "assets/icons/filled_circle.svg?react";
import {AccordionTableCellContent} from "common/components/styledComponent/AccordionTableCellContent";
import DataAndMeasuresCell from "common/components/table/DataAndMeasuresCell";
import HoursAndMinutesCell from "common/components/table/HoursAndMinutesCell";
import {CustomizableTableHeaders} from "common/components/table/TableWithCustomizableColumns";
import {UnreachableCode} from "common/components/util/UnreachableCode";
import {Dash} from "common/constants";
import {EventName} from "common/constants/events";
import {useI18n} from "common/hooks/useI18n";
import {useTracking} from "common/hooks/useTracking";
import {AccountContextProvider} from "features/account/accountStateProvider";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Tooltip} from "@bolteu/kalep-react";
import {Star} from "@bolteu/kalep-react-icons";
import {DefaultCellContent} from "@bolteu/kalep-table-react/build/components/CellContent";

import {OnlineHoursConfig} from "../..";
import {DriverTableData} from "../../components/DriversTable";

import EntityPortalStatus = FleetOwnerPortalService.EntityPortalStatus;

export const getDriverStatusIcon = (status: EntityPortalStatus): JSX.Element => {
    switch (status) {
        case EntityPortalStatus.Active:
            return <></>;
        case EntityPortalStatus.Blocked:
            return <FilledCircle width={12} height={12} className="fill-red-500" />;
        case EntityPortalStatus.Deactivated:
            return <FilledCircle width={12} height={12} className="fill-neutral-500" />;
        default:
            return UnreachableCode.never(status, <></>);
    }
};

const getDriverNameCellContent = (
    driverName: string,
    status: EntityPortalStatus,
    statusText: string,
): React.ReactNode => {
    switch (status) {
        case EntityPortalStatus.Active:
            return <p>{driverName}</p>;
        case EntityPortalStatus.Blocked:
            return (
                <div>
                    <p>{driverName}</p>
                    <p className="text-red-500">{statusText}</p>
                </div>
            );
        case EntityPortalStatus.Deactivated:
            return (
                <div>
                    <p className="text-tertiary">{driverName}</p>
                    <p className="text-tertiary">{statusText}</p>
                </div>
            );
        default:
            return UnreachableCode.never(status, null);
    }
};

export function useTableColumns(config: OnlineHoursConfig, isMobileView?: boolean) {
    const fleetId = useContext(AccountContextProvider)?.getFleet()?.id;
    const navigate = useNavigate();
    const {i18n} = useI18n();
    const {trackEvent} = useTracking();

    const CellComponent = isMobileView ? AccordionTableCellContent : DefaultCellContent;

    return useMemo<CustomizableTableHeaders<DriverTableData>>(() => {
        const goToDriver = (driverId: string) => () => {
            if (!fleetId) {
                return;
            }

            trackEvent(EventName.EngagementDashboardDriverClicked);
            navigate(driverId);
        };

        return {
            driver_name: {
                label: i18n("auth.app.fleet.engagement-dashboard.header.driver"),
                renderCell: (item: DriverTableData) => {
                    const statusText = i18n(`auth.app.fleet.engagement-dashboard.tooltip.${item.status}`);
                    return (
                        <CellComponent>
                            <div className="flex items-center justify-start gap-2">
                                <div className="shrink-0">
                                    <Tooltip content={statusText} placement="right">
                                        <div>{getDriverStatusIcon(item.status)}</div>
                                    </Tooltip>
                                </div>
                                <button
                                    type="button"
                                    onClick={goToDriver(item.id)}
                                    className="text-left hover:underline"
                                >
                                    {getDriverNameCellContent(item.driver_name, item.status, statusText)}
                                </button>
                            </div>
                        </CellComponent>
                    );
                },
                nonConfigurable: true,
            },
            email: {
                label: i18n("auth.app.fleet.driver.email"),
                renderCell: (item: DriverTableData) => <DefaultCellContent>{item.email}</DefaultCellContent>,
                hideByDefault: true,
            },
            phone: {
                label: i18n("auth.app.fleet.driver.phone"),
                renderCell: (item: DriverTableData) => <DefaultCellContent>{item.phone}</DefaultCellContent>,
                hideByDefault: true,
            },
            cash_rides: {
                label: i18n("auth.app.fleet.engagement-dashboard.header.cash_rides"),
                renderCell: (item: DriverTableData) => {
                    return (
                        !item.has_cash_payment && (
                            <CellComponent>
                                <div className="flex items-center justify-center">
                                    <Tooltip content={i18n(`driver.cash_rides_disabled`)} placement="bottom">
                                        <div>
                                            <CashlessIcon />
                                        </div>
                                    </Tooltip>
                                </div>
                            </CellComponent>
                        )
                    );
                },
            },
            driver_score: {
                label: i18n("auth.app.fleet.engagement-dashboard.header.driver_score"),
                renderCell: (item: DriverTableData) => (
                    <DataAndMeasuresCell
                        className={item.driver_score === Dash.EM ? "text-content-tertiary" : ""}
                        data={item.driver_score}
                        measure={i18n("common.percentage")}
                    />
                ),
            },
            finished_orders: {
                label: i18n("auth.app.fleet.engagement-dashboard.header.finished_orders"),
                renderCell: (item: DriverTableData) => (
                    <CellComponent className={item.finished_orders === Dash.EM ? "text-content-tertiary" : ""}>
                        {item.finished_orders}
                    </CellComponent>
                ),
            },
            total_acceptance: {
                label: i18n("auth.app.fleet.engagement-dashboard.header.total_acceptance"),
                tooltip: i18n("auth.app.fleet.reports.tooltip.total_acceptance"),
                renderCell: (item: DriverTableData) => (
                    <DataAndMeasuresCell
                        className={item.total_acceptance === Dash.EM ? "text-content-tertiary" : ""}
                        data={item.total_acceptance}
                        measure={i18n("common.percentage")}
                    />
                ),
                hideByDefault: true,
            },
            required_acceptance: {
                label: i18n("auth.app.fleet.engagement-dashboard.header.non_optional_acceptance"),
                tooltip: i18n("auth.app.fleet.reports.tooltip.non_optional_acceptance"),
                renderCell: (item: DriverTableData) => (
                    <DataAndMeasuresCell
                        className={item.required_acceptance === Dash.EM ? "text-content-tertiary" : ""}
                        data={item.required_acceptance}
                        measure={i18n("common.percentage")}
                    />
                ),
            },
            ...(config.shouldShowOnlineHours && {
                online_hours: {
                    label: i18n("auth.app.fleet.engagement-dashboard.header.online_hours"),
                    renderCell: (item: DriverTableData) => (
                        <HoursAndMinutesCell
                            valueInMinutes={item.online_min}
                            hoursLabel={i18n("common.hour_abbreviation")}
                            minutesLabel={i18n("common.minute_abbreviation")}
                        />
                    ),
                },
            }),
            ...(config.shouldShowWaitingOrders && {
                waiting_orders_min: {
                    label: i18n("auth.app.fleet.engagement-dashboard.header.waiting_orders"),
                    tooltip: i18n("auth.app.fleet.engagement-dashboard.header.waiting_orders_tooltip"),
                    renderCell: (item: DriverTableData) => (
                        <HoursAndMinutesCell
                            valueInMinutes={item.waiting_orders_min}
                            hoursLabel={i18n("common.hour_abbreviation")}
                            minutesLabel={i18n("common.minute_abbreviation")}
                        />
                    ),
                },
            }),
            ...(config.shouldShowHasOrder && {
                has_order_min: {
                    label: i18n("auth.app.fleet.engagement-dashboard.header.has_order"),
                    tooltip: i18n("auth.app.fleet.engagement-dashboard.header.has_order_tooltip"),
                    renderCell: (item: DriverTableData) => (
                        <HoursAndMinutesCell
                            valueInMinutes={item.has_order_min}
                            hoursLabel={i18n("common.hour_abbreviation")}
                            minutesLabel={i18n("common.minute_abbreviation")}
                        />
                    ),
                },
            }),
            ...(config.shouldShowUtilization && {
                utilization: {
                    label: i18n("auth.app.fleet.engagement-dashboard.header.utilization"),
                    renderCell: (item: DriverTableData) => (
                        <DataAndMeasuresCell
                            className={item.utilization === Dash.EM ? "text-content-tertiary" : ""}
                            data={item.utilization}
                            measure={i18n("common.percentage")}
                        />
                    ),
                },
            }),
            finished_rate: {
                label: i18n("auth.app.fleet.engagement-dashboard.header.finish_rate"),
                tooltip: i18n("auth.app.fleet.engagement-dashboard.header.finish_rate_tooltip"),
                renderCell: (item: DriverTableData) => (
                    <DataAndMeasuresCell
                        className={item.finished_rate === Dash.EM ? "text-content-tertiary" : ""}
                        data={item.finished_rate}
                        measure={i18n("common.percentage")}
                    />
                ),
            },
            completion_rate: {
                label: i18n("auth.app.fleet.engagement-dashboard.header.completion_rate"),
                tooltip: i18n("auth.app.fleet.engagement-dashboard.header.completion_rate_tooltip"),
                renderCell: (item: DriverTableData) => (
                    <DataAndMeasuresCell
                        className={item.completion_rate === Dash.EM ? "text-content-tertiary" : ""}
                        data={item.completion_rate}
                        measure={i18n("common.percentage")}
                    />
                ),
            },
            average_trip_distance: {
                label: i18n("auth.app.fleet.engagement-dashboard.header.average_trip_distance"),
                renderCell: (item: DriverTableData) => (
                    <DataAndMeasuresCell
                        data={
                            item.average_trip_distance ? Number(item.average_trip_distance / 1000).toFixed(1) : Dash.EM
                        }
                        measure="km"
                    />
                ),
                hideByDefault: true,
            },
            average_driver_rating: {
                label: i18n("auth.app.fleet.engagement-dashboard.header.average_driver_rating"),
                renderCell: (item: DriverTableData) => (
                    <DefaultCellContent>
                        {item.average_driver_rating ? (
                            <div className="flex items-center">
                                <span className="mr-1">{Number(item.average_driver_rating).toFixed(1)}</span>
                                <Star width={10} height={10} className="text-content-tertiary" />
                            </div>
                        ) : (
                            <div className="text-tertiary">{Dash.EM}</div>
                        )}
                    </DefaultCellContent>
                ),
                hideByDefault: true,
            },
            uuid: {
                label: i18n("auth.app.fleet.driver.uuid"),
                renderCell: (item: DriverTableData) => (
                    <DefaultCellContent>
                        {item.uuid ? (
                            <div className="text-base">{item.uuid}</div>
                        ) : (
                            <div className="text-tertiary">{Dash.EM}</div>
                        )}
                    </DefaultCellContent>
                ),
                hideByDefault: true,
                tooltip: i18n("auth.app.fleet.driver.uuid_tooltip"),
            },
            partner_uuid: {
                label: i18n("auth.app.fleet.driver.partner_uuid"),
                renderCell: (item: DriverTableData) => (
                    <DefaultCellContent>
                        {item.partner_uuid ? (
                            <div className="text-base">{item.partner_uuid}</div>
                        ) : (
                            <div className="text-tertiary">{Dash.EM}</div>
                        )}
                    </DefaultCellContent>
                ),
                tooltip: i18n("auth.app.fleet.driver.partner_uuid_tooltip"),
                hideByDefault: true,
            },
        };
    }, [
        fleetId,
        config.shouldShowOnlineHours,
        config.shouldShowWaitingOrders,
        config.shouldShowHasOrder,
        config.shouldShowUtilization,
        i18n,
        navigate,
        trackEvent,
        CellComponent,
    ]);
}
