import {ChangeEventHandler, useCallback, useState} from "react";

import {ResponsiveDialog} from "common/components/dialog/ResponsiveDialog";
import {useI18n} from "common/hooks/useI18n";
import {useTailwindViewport} from "common/hooks/useTailwindViewport";
import {restrictNumberInputToTime, TimeType} from "common/util/timeUtil";
import {v4} from "uuid";

import {FleetShiftManagementService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Alert, Button, Dialog, Grid, TextField, Typography} from "@bolteu/kalep-react";
import {Plus} from "@bolteu/kalep-react-icons";

import {BreakRow} from "./BreakRow";

import BreakType = FleetShiftManagementService.BreakType;

export enum ShiftFormType {
    EDIT = "edit",
    ADD = "add",
}

export interface Props {
    type: ShiftFormType;
    closeDialog: () => void;
    onSubmit: (shift: FleetShiftManagementService.ShiftAttributes) => void;
    initialState?: ShiftFormState;
    isLoading: boolean;
}

export interface ShiftBreakState {
    breakStartHour: string;
    breakStartMinute: string;
    breakDuration: string;
    key: string;
}

export interface ShiftFormState {
    shiftName: string;
    shiftStartHour: string;
    shiftStartMinute: string;
    shiftEndHour: string;
    shiftEndMinute: string;
    breaks: ShiftBreakState[];
}

const DEFAULT_INITIAL_STATE: ShiftFormState = {
    shiftName: "",
    shiftStartHour: "",
    shiftStartMinute: "",
    shiftEndHour: "",
    shiftEndMinute: "",
    breaks: [{breakStartHour: "", breakStartMinute: "", breakDuration: "", key: v4()}],
};

const formatTime = (hours: string, minutes: string) => `${hours}:${minutes}`;

const isBreakFilled = (breakDetail: {breakStartHour: string; breakStartMinute: string; breakDuration: string}) => {
    return breakDetail.breakDuration !== "" && breakDetail.breakStartHour !== "" && breakDetail.breakStartMinute !== "";
};

const validateShift = (shiftDetails: Omit<ShiftFormState, "breaks">, breakDetails: ShiftBreakState[]) => {
    const isAllBreaksFilled = breakDetails.every(isBreakFilled);
    const isAtLeastOneBreakFilled = isAllBreaksFilled || breakDetails.some(isBreakFilled);
    const isFormValid =
        shiftDetails.shiftName &&
        shiftDetails.shiftStartHour &&
        shiftDetails.shiftStartMinute &&
        shiftDetails.shiftEndHour &&
        shiftDetails.shiftEndMinute &&
        isAtLeastOneBreakFilled;
    return {isAllBreaksFilled, isFormValid};
};

export const ShiftActionDialog = ({
    type,
    closeDialog,
    onSubmit,
    isLoading,
    initialState = DEFAULT_INITIAL_STATE,
}: Props) => {
    const {i18n} = useI18n();
    const [shiftDetails, setShiftDetails] = useState<Omit<ShiftFormState, "breaks">>({
        shiftName: initialState.shiftName,
        shiftStartHour: initialState.shiftStartHour,
        shiftStartMinute: initialState.shiftStartMinute,
        shiftEndHour: initialState.shiftEndHour,
        shiftEndMinute: initialState.shiftEndMinute,
    });
    const [breakDetails, setBreakDetails] = useState<ShiftBreakState[]>(initialState.breaks);
    const isMobileView = useTailwindViewport() === "sm";

    const handleInputChange = useCallback<ChangeEventHandler<HTMLInputElement>>((e) => {
        const {value, dataset} = e.target;
        const breakDataIndex = dataset.index !== undefined ? Number(dataset.index) : null;
        const dataType = dataset.type !== undefined ? dataset.type : null;
        const {name} = dataset;

        if (!name) {
            return;
        }

        let newValue: string;
        if (dataType === "hour") {
            newValue = restrictNumberInputToTime(value, TimeType.HOUR);
        } else if (dataType === "minute") {
            newValue = restrictNumberInputToTime(value, TimeType.MINUTE);
        } else {
            newValue = value;
        }

        if (breakDataIndex !== null) {
            setBreakDetails((prevBreaks) =>
                prevBreaks.map((breakDetail, i) =>
                    i === breakDataIndex ? {...breakDetail, [name]: newValue} : breakDetail,
                ),
            );
        } else {
            setShiftDetails((prevDetails) => {
                return {...prevDetails, [name]: newValue};
            });
        }
    }, []);

    const removeBreak = useCallback((index: number) => {
        setBreakDetails((prevBreakDetails) => {
            prevBreakDetails.splice(index, 1);
            return [...prevBreakDetails];
        });
    }, []);

    const onSubmitWrapper = useCallback(async () => {
        const formattedBreaks = breakDetails.filter(isBreakFilled).map((breakDetail) => ({
            type: BreakType.START_DURATION,
            start_time: formatTime(breakDetail.breakStartHour, breakDetail.breakStartMinute),
            duration_min: Number(breakDetail.breakDuration),
        }));

        onSubmit({
            name: shiftDetails.shiftName,
            start_time: formatTime(shiftDetails.shiftStartHour, shiftDetails.shiftStartMinute),
            end_time: formatTime(shiftDetails.shiftEndHour, shiftDetails.shiftEndMinute),
            config: {
                capacity: null,
                breaks: formattedBreaks,
            },
        });
    }, [breakDetails, onSubmit, shiftDetails]);

    const addBreak = useCallback(() => {
        setBreakDetails((prevBreakDetails) => [
            ...prevBreakDetails,
            {breakStartHour: "", breakStartMinute: "", breakDuration: "", key: v4()},
        ]);
    }, []);

    const {isAllBreaksFilled, isFormValid} = validateShift(shiftDetails, breakDetails);

    const isEditForm = type === ShiftFormType.EDIT;
    const title = isEditForm
        ? i18n("auth.app.fleet.shifts.edit_shift.title")
        : i18n("auth.app.fleet.shifts.add_shift.title");

    return (
        <ResponsiveDialog title={title} isOpen onRequestClose={closeDialog}>
            <Dialog.Content>
                {isEditForm && (
                    <Alert severity="info" overrideClassName="mb-4">
                        <Typography fontWeight="semibold">
                            {i18n("auth.app.fleet.shifts.edit_shift.info_alert")}
                        </Typography>
                    </Alert>
                )}
                <Grid columns={12}>
                    <Grid.Item colSpan={12}>
                        <TextField
                            data-name="shiftName"
                            type="text"
                            label={i18n("common.name")}
                            onChange={handleInputChange}
                            value={shiftDetails.shiftName}
                            required
                            fullWidth
                        />
                    </Grid.Item>
                    <Grid.Item colSpan={6}>
                        <div className="flex h-full items-end gap-2 pb-2">
                            <TextField
                                data-name="shiftStartHour"
                                data-type="hour"
                                type="number"
                                placeholder={i18n("common.hour_placeholder")}
                                label={i18n("auth.app.fleet.shifts.shift_form.shift_start")}
                                onChange={handleInputChange}
                                value={shiftDetails.shiftStartHour}
                                required
                                fullWidth
                            />
                            <div className="mb-3">:</div>
                            <TextField
                                data-name="shiftStartMinute"
                                data-type="minute"
                                type="number"
                                placeholder={i18n("common.minute_placeholder")}
                                onChange={handleInputChange}
                                value={shiftDetails.shiftStartMinute}
                                required
                                fullWidth
                            />
                        </div>
                    </Grid.Item>
                    <Grid.Item colSpan={6}>
                        <div className="flex h-full items-end gap-2 pb-2">
                            <TextField
                                data-name="shiftEndHour"
                                data-type="hour"
                                type="number"
                                placeholder={i18n("common.hour_placeholder")}
                                label={i18n("auth.app.fleet.shifts.shift_form.shift_end")}
                                onChange={handleInputChange}
                                value={shiftDetails.shiftEndHour}
                                required
                                fullWidth
                            />
                            <div className="mb-3">:</div>
                            <TextField
                                data-name="shiftEndMinute"
                                data-type="minute"
                                type="number"
                                placeholder={i18n("common.minute_placeholder")}
                                onChange={handleInputChange}
                                value={shiftDetails.shiftEndMinute}
                                required
                                fullWidth
                            />
                        </div>
                    </Grid.Item>
                    <Grid.Item colSpan={12}>
                        <hr className="border-neutral-200 pb-2" />
                    </Grid.Item>
                    {breakDetails.map((breakDetail, index) => (
                        <Grid.Item colSpan={12} key={breakDetail.key}>
                            <BreakRow
                                index={index}
                                data={breakDetail}
                                handleInputChange={handleInputChange}
                                onDeleteClick={removeBreak}
                                isOnlyBreak={breakDetails.length < 2}
                            />
                        </Grid.Item>
                    ))}
                    <Grid.Item colSpan={12}>
                        <Button
                            type="button"
                            size="sm"
                            startIcon={<Plus />}
                            variant={isAllBreaksFilled ? "tertiary" : "static-light"}
                            onClick={addBreak}
                            disabled={!isAllBreaksFilled}
                            overrideClassName="mt-1"
                        >
                            {i18n("auth.app.fleet.shifts.shift_form.add_break")}
                        </Button>
                    </Grid.Item>
                </Grid>
            </Dialog.Content>
            <Dialog.Footer>
                <Button loading={isLoading} disabled={!isFormValid} onClick={onSubmitWrapper} fullWidth={isMobileView}>
                    {i18n("common.submit")}
                </Button>
            </Dialog.Footer>
        </ResponsiveDialog>
    );
};
