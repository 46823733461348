import {useI18n} from "common/hooks/useI18n";

import {FleetShiftManagementService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Typography} from "@bolteu/kalep-react";
import {CoffeeBreakOutlined} from "@bolteu/kalep-react-icons";

interface Props {
    breaks: FleetShiftManagementService.StartDurationBreak[];
}

export const Breaks = ({breaks}: Props) => {
    const {i18n} = useI18n();

    const shouldShowBreakNumbers = breaks.length > 1;

    return (
        <>
            {breaks.map((breakDetails, index) => (
                <div
                    key={`${breakDetails.start_time}-${breakDetails.duration_min}`}
                    className="flex items-center justify-start gap-2"
                >
                    <div className="bg-neutral-secondary text-content-secondary h-[28px] w-[28px] rounded-full text-center">
                        <CoffeeBreakOutlined size="xs" />
                    </div>
                    <div className="flex flex-col">
                        <Typography>
                            {breakDetails.start_time} • {breakDetails.duration_min} {i18n("common.minute_abbreviation")}
                        </Typography>
                        <Typography variant="body-secondary" color="secondary">
                            {i18n("auth.app.fleet.shifts.planning.card.break")}
                            {shouldShowBreakNumbers && ` ${index + 1}`}
                        </Typography>
                    </div>
                </div>
            ))}
        </>
    );
};
