import {FC, useCallback, useContext, useMemo} from "react";
import {useNavigate} from "react-router-dom";

import FilledCircle from "assets/icons/filled_circle.svg?react";
import NoResults from "common/components/NoResults";
import LoadingSpinner from "common/components/spinner";
import AccordionTableWithCustomizableColumns from "common/components/table/AccordionTableWithCustomizableColumns";
import TableWithCustomizableColumns from "common/components/table/TableWithCustomizableColumns";
import {UnreachableCode} from "common/components/util/UnreachableCode";
import {Dash} from "common/constants";
import {EventName} from "common/constants/events";
import {useI18n} from "common/hooks/useI18n";
import {useTailwindViewport} from "common/hooks/useTailwindViewport";
import {useTracking} from "common/hooks/useTracking";
import {AccountContextProvider} from "features/account/accountStateProvider";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {RowActionContext} from "@bolteu/kalep-table-react";

import {OnlineHoursConfig} from "..";
import {useAccordionTableColumns} from "../hooks/driver/useAccordionTableColumns";
import {getDriverStatusIcon, useTableColumns} from "../hooks/driver/useTableColumns";
import {AddDriver} from "./AddDriver";
import EngagementDataRangeDateDriver = FleetOwnerPortalService.EngagementDataRangeDateDriver;

export const DRIVERS_TABLE_ID = "driver-list-table";

interface TableBodyProps {
    dashboardData?: EngagementDataRangeDateDriver[];
    onlineHoursConfig: OnlineHoursConfig;
    isLoading: boolean;
}

const getRateDisplay = (value: number | null) => {
    return value ? `${Math.round(value)}` : Dash.EM;
};

const getFinishedOrders = (driver: EngagementDataRangeDateDriver) => {
    const data = driver.finished_orders;
    return data ? `${data}` : Dash.EM;
};

export interface DriverTableData {
    id: string;
    uuid: string;
    partner_uuid: string;
    status: FleetOwnerPortalService.EntityPortalStatus;
    driver_name: string;
    email: string;
    phone: string;
    has_cash_payment: boolean;
    driver_score: string;
    finished_orders: string;
    total_acceptance: string;
    required_acceptance: string;
    online_min: number;
    utilization: string;
    finished_rate: string;
    completion_rate: string;
    average_trip_distance: number;
    average_driver_rating: number;
    waiting_orders_min: number;
    has_order_min: number;
}

const DriversTable: FC<TableBodyProps> = ({dashboardData, onlineHoursConfig, isLoading}) => {
    const {i18n} = useI18n();
    const navigate = useNavigate();
    const fleetId = useContext(AccountContextProvider)?.getFleet()?.id;
    const {trackEvent} = useTracking();

    const viewport = useTailwindViewport();
    const isMobileView = viewport === "sm";
    const columns = useTableColumns(onlineHoursConfig, isMobileView);

    const noDriversPlaceholder = useMemo(() => {
        return (
            <NoResults
                titleKey="auth.app.fleet.drivers.no-drivers-yet"
                subTitleKey="auth.app.fleet.drivers.driver-add"
                showActionComponent
                actionComponent={<AddDriver />}
            />
        );
    }, []);

    const renderEmptyPlaceholder = useCallback(() => {
        return isLoading ? <LoadingSpinner show parentClassName="mt-2" /> : noDriversPlaceholder;
    }, [isLoading, noDriversPlaceholder]);

    const accordionTableColumns = useAccordionTableColumns(onlineHoursConfig);

    const tableData = useMemo((): DriverTableData[] => {
        if (!dashboardData) {
            return [];
        }
        return dashboardData.map((d) => ({
            id: String(d.id),
            uuid: String(d.uuid ?? ""),
            partner_uuid: String(d.partner_uuid ?? ""),
            status: d.portal_status,
            email: String(d.email),
            phone: String(d.phone),
            driver_name: `${d.first_name} ${d.last_name}`,
            has_cash_payment: d.has_cash_payment,
            driver_score: getRateDisplay(d.driver_score),
            finished_orders: getFinishedOrders(d),
            total_acceptance: getRateDisplay(d.acceptance_rate),
            required_acceptance: getRateDisplay(d.acceptance_rate_without_optional_rides),
            online_min: d.online_min ?? 0,
            waiting_orders_min: d.waiting_orders_min ?? 0,
            has_order_min: d.has_order_min ?? 0,
            utilization: getRateDisplay(d.utilization),
            finished_rate: getRateDisplay(d.finished_rate),
            completion_rate: getRateDisplay(d.completion_rate),
            average_driver_rating: d.avg_driver_rating,
            average_trip_distance: d.avg_ride_distance,
        }));
    }, [dashboardData]);

    const getItemHeader = useCallback(
        (item: DriverTableData) => {
            let statusTextClassName;
            let statusText;

            switch (item.status) {
                case FleetOwnerPortalService.EntityPortalStatus.Active:
                    break;
                case FleetOwnerPortalService.EntityPortalStatus.Blocked:
                    statusTextClassName = "text-danger-primary";
                    statusText = i18n("driver.status.blocked");
                    break;
                case FleetOwnerPortalService.EntityPortalStatus.Deactivated:
                    statusTextClassName = "text-tertiary";
                    statusText = i18n("driver.status.deactivated");
                    break;
                default:
                    UnreachableCode.never(item.status);
            }

            return {
                primary: item.driver_name,
                secondary: (
                    <div className="flex items-center gap-x-1">
                        {statusTextClassName && statusText && (
                            <>
                                <div className={statusTextClassName}>{statusText}</div>
                                <FilledCircle width={4} height={4} className="self-center fill-neutral-700" />
                            </>
                        )}
                        {columns.driver_score?.label}: {item.driver_score}
                    </div>
                ),
                renderStartSlot: () => getDriverStatusIcon(item.status),
            };
        },
        [i18n, columns.driver_score?.label],
    );

    const goToDriverProfile = useCallback(
        (driverId: string) => () => {
            if (!fleetId) {
                return;
            }

            trackEvent(EventName.EngagementDashboardDriverClicked);
            navigate(driverId);
        },
        [fleetId, navigate, trackEvent],
    );

    const inlineActions = useMemo(() => {
        return [
            {
                label: i18n("driver.open_profile"),
                customInlineRenderer: (context: RowActionContext<DriverTableData>) => {
                    const {id} = context.row;

                    return (
                        <button type="button" onClick={goToDriverProfile(id)}>
                            <div className="text-action-primary">{i18n("driver.open_profile")}</div>
                        </button>
                    );
                },
            },
        ];
    }, [i18n, goToDriverProfile]);

    if (isMobileView) {
        if (!isLoading && !tableData.length) {
            return noDriversPlaceholder;
        }

        return (
            <AccordionTableWithCustomizableColumns
                getItemHeader={getItemHeader}
                columns={accordionTableColumns}
                items={tableData}
                inlineActions={inlineActions}
                aria-label="Driver table"
                isLoading={isLoading}
                paddingStart={0}
                paddingEnd={0}
                id={DRIVERS_TABLE_ID}
            />
        );
    }
    return (
        <TableWithCustomizableColumns
            columns={columns}
            items={tableData}
            aria-label="Driver table"
            renderEmptyPlaceholder={renderEmptyPlaceholder}
            id={DRIVERS_TABLE_ID}
            isLoading={isLoading}
        />
    );
};

export default DriversTable;
