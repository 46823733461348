import {useCallback, useState} from "react";

import {ComboboxText} from "common/components/dynamicForm/ComboboxText";
import {AdditionalFields, FormValueType} from "common/components/dynamicForm/types";
import {getApiToFormValues, getFilteredAndSortedFields, getFormField} from "common/components/dynamicForm/util";
import {ValidatorResult} from "common/constants/types";
import {useI18n} from "common/hooks/useI18n";
import {Step} from "features/companies/vehicleListings/add-listing/mock";

import {FleetOwnerRegistrationService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {FormChange, FormState, useForm} from "@bolteu/kalep-form-react";
import {Button, Typography} from "@bolteu/kalep-react";

interface Props {
    step: Step;
    onNextStep: () => void;
    onPrevStep: () => void;
}

const WizardContenHeader = ({title, subtitle}: {title: string; subtitle?: string}) => {
    return (
        <div className="flex flex-col gap-6">
            <Typography variant="title-primary" fontSize="text-3xl">
                {title}
            </Typography>
            {subtitle && <Typography variant="body-primary">{subtitle}</Typography>}
        </div>
    );
};

const WizardContent = ({step, onNextStep, onPrevStep}: Props) => {
    const {i18n} = useI18n();
    const {fields} = step;

    // TODO: implement submit and validation error handling
    const onSubmitForm = useCallback(
        (_state: FormState<FormValueType>) => {
            onNextStep();
            return Promise.resolve();
        },
        [onNextStep],
    );

    const [formFields] = useState<FleetOwnerRegistrationService.FormField[]>(fields);

    const [validatorResults] = useState<ValidatorResult[]>([]);
    const [formValue, setFormValue] = useState<FormValueType>(getApiToFormValues(formFields));

    const onChange = useCallback(async (formData: FormChange<FormValueType>) => {
        const {data} = formData;
        setFormValue(data);
    }, []);

    const useFormValue = useForm<FormValueType, AdditionalFields>(formValue, {
        ComboboxTextField: ComboboxText,
    });
    const {Form, Button: FormButton} = useFormValue;

    const getField = (field: FleetOwnerRegistrationService.FormField) => {
        const {type} = field;
        if (type === FleetOwnerRegistrationService.FormFieldType.FILEUPLOAD) {
            return <div>Test File Upload</div>; // TODO Implement File Upload
        }
        return getFormField(field, validatorResults, useFormValue);
    };

    return (
        <div>
            <Form validatorResults={validatorResults} onSubmit={onSubmitForm} onChange={onChange}>
                <div className="mt-6 flex max-w-lg flex-col gap-6">
                    {getFilteredAndSortedFields(formFields).map((field) => (
                        <div key={field.name}>{getField(field)}</div>
                    ))}
                </div>
                <div className="mt-8 flex justify-between">
                    {step.position !== 0 && (
                        <Button variant="secondary" onClick={onPrevStep}>
                            {i18n("common.back")}
                        </Button>
                    )}
                    <FormButton submit>{i18n("form.button.next")}</FormButton>
                </div>
            </Form>
        </div>
    );
};

export default WizardContent;
export {WizardContenHeader};
