import {useCallback, useEffect, useState} from "react";

import {EventName, EventPropertiesFormSubmissionFailedName} from "common/constants/events";
import {useAreaCodeOptions} from "common/geo/hooks/useAreaCodeOptions";
import {useI18n} from "common/hooks/useI18n";
import {useTracking} from "common/hooks/useTracking";
import {FleetOwnerAuthNoAuthApiClient} from "common/services/apiClients/noAuthApiClient";
import {LocationItem} from "common/services/authService";
import {getItemKey} from "common/services/localStorageService";
import {isError, isValidationError} from "common/util/isErrorType";
import config from "config";
import {useIpLocationData} from "@fleet/common/hooks/useIpLocationData";

import {FleetOwnerAuthNoAuthService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {FormState, useForm} from "@bolteu/kalep-form-react";
import {Alert, Grid} from "@bolteu/kalep-react";

interface ForgotPasswordForm {
    email: string;
    areaCodeCountry: string;
    phone: string;
}

export function useForgotPasswordForm(setEmailSentTo: (email: string) => void) {
    const {i18n} = useI18n();
    const [apiErrorMessage, setApiErrorMessage] = useState<string | null>(null);
    const {trackEvent} = useTracking();

    const locationData = useIpLocationData(config.geoServiceUrl, getItemKey(LocationItem));
    const {options, renderOption, countryData} = useAreaCodeOptions();
    const {Form, TextField, AutocompleteField, Button, setData} = useForm<ForgotPasswordForm, Record<never, never>>({
        email: "",
        phone: "",
        areaCodeCountry: "",
    });

    useEffect(() => {
        if (locationData) {
            setData({areaCodeCountry: locationData.country_iso_code});
        }
    }, [locationData, setData]);

    const handleSubmit = useCallback(
        async (state: FormState<ForgotPasswordForm>) => {
            setApiErrorMessage(null);
            const {email, phone, areaCodeCountry} = state.data;
            const country = countryData[areaCodeCountry];
            const body: FleetOwnerAuthNoAuthService.RequestPasswordEmailRequest = {
                email: email.trim(),
                phone: `${country?.areaCode}${phone.trim()}`,
            };
            try {
                await FleetOwnerAuthNoAuthApiClient.requestPasswordResetEmail(body);
                setEmailSentTo(email);
                trackEvent(EventName.ForgotPasswordEmailSent);
            } catch (e) {
                let eventErrorMessage = isError(e) ? e.message : "";
                if (isValidationError(e)) {
                    eventErrorMessage = "INVALID_REQUEST";
                    setApiErrorMessage(i18n("forgot-password.invalid_credentials_short"));
                } else {
                    setApiErrorMessage(i18n("api.default_error"));
                }
                trackEvent(EventName.FormSubmissionFailed, {
                    formName: EventPropertiesFormSubmissionFailedName.ForgotPassword,
                    errorMessage: eventErrorMessage,
                });
            }
        },
        [countryData, i18n, setEmailSentTo, trackEvent],
    );

    return (
        <Form onSubmit={handleSubmit} className="flex w-full max-w-[356px] flex-col gap-4">
            {apiErrorMessage && (
                <Alert severity="error" overrideClassName="text-base mb-2">
                    {apiErrorMessage}
                </Alert>
            )}
            <TextField
                id="email"
                name="email"
                type="text"
                label={i18n("common.email")}
                placeholder={i18n("forgot-password.email_palceholder")}
                required
                autoComplete="on"
            />
            <Grid columns={3} alignItems="flex-end" gap={2}>
                <Grid.Item colSpan={1}>
                    <AutocompleteField
                        name="areaCodeCountry"
                        options={options}
                        label={i18n("common.phone")}
                        renderOption={renderOption}
                        required
                    />
                </Grid.Item>
                <Grid.Item colSpan={2}>
                    <TextField
                        id="phone"
                        name="phone"
                        type="tel"
                        placeholder={i18n("common.phone")}
                        label=""
                        required
                        autoComplete="on"
                    />
                </Grid.Item>
            </Grid>
            <Button fullWidth overrideClassName="mt-2" submit>
                {i18n("forgot-password.reset_password")}
            </Button>
        </Form>
    );
}
