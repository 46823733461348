import {useCallback, useRef} from "react";
import {useNavigate} from "react-router-dom";

import {useI18n} from "common/hooks/useI18n";
import {useTooltipClick} from "common/hooks/useTooltipClick";

import {Accordion, AccordionItem, Tooltip} from "@bolteu/kalep-react";
import {InfoCircleOutlined} from "@bolteu/kalep-react-icons";

import {CellProps, CellRenderer, ReferenceColumnContent, UseableColumns} from "./Common";

type ApiDrivenTableMobileProps = UseableColumns & {
    numberOfRecords: number;
};

export function ApiDrivenTableMobile(apiDrivenTableMobileProps: ApiDrivenTableMobileProps) {
    const {referenceColumn, userDefinedVisibleColumns, firstColumn, stateColumn, numberOfRecords} =
        apiDrivenTableMobileProps;
    const {i18n} = useI18n();
    const navigator = useNavigate();

    const goToVehicleProfile = useCallback(
        (vehicleId: string) => () => {
            navigator(vehicleId);
        },
        [navigator],
    );

    return (
        <div className="w-full">
            {Array.from({length: numberOfRecords}).map((_, rowIdx) => (
                <Accordion key={referenceColumn?.cells[rowIdx]}>
                    <AccordionItem
                        id={`${rowIdx}`}
                        title={{
                            primary: (
                                <ReferenceColumnContent
                                    column={firstColumn}
                                    valueAtIdx={rowIdx}
                                    referenceColumn={referenceColumn}
                                    stateColumn={stateColumn}
                                    subDisplayColumn={userDefinedVisibleColumns[0]}
                                />
                            ),
                        }}
                    >
                        <ul className="m-0 flex list-none flex-col gap-4 p-0">
                            {userDefinedVisibleColumns.map((column) => (
                                <li key={column.key}>
                                    <AccordionTableCell column={column} valueAtIdx={rowIdx} />
                                </li>
                            ))}
                        </ul>
                        <div className="pt-5">
                            <button
                                type="button"
                                onClick={goToVehicleProfile((referenceColumn?.cells[rowIdx] as string) ?? "")}
                            >
                                <div className="text-action-primary">{i18n("vehicle.open_profile")}</div>
                            </button>
                        </div>
                    </AccordionItem>
                </Accordion>
            ))}
        </div>
    );
}

export function AccordionTableCell(cellProps: CellProps) {
    const ref = useRef<HTMLElement | undefined>(undefined);

    function addRef() {
        return (r: HTMLElement | null) => {
            if (r) {
                ref.current = r;
            }
        };
    }

    const {isOpen, setIsOpen, onClick} = useTooltipClick({delay: 3000});

    return (
        <div className="flex gap-y-1 gap-x-2">
            <span className="text-content-secondary m-0 flex items-center gap-2 text-sm font-normal" ref={addRef()}>
                {cellProps.column.title}
                {cellProps.column.tooltip && (
                    <Tooltip
                        content={cellProps.column.tooltip}
                        isOpen={isOpen}
                        onOpenChange={setIsOpen}
                        placement="top"
                        boundaryElement={ref.current}
                    >
                        <div>
                            <InfoCircleOutlined width={16} height={16} onClick={onClick} />
                        </div>
                    </Tooltip>
                )}
            </span>
            <div className="border-separator min-w-[8px] flex-1 grow border-0 border-b" />
            <span>
                <CellRenderer {...cellProps} />
            </span>
        </div>
    );
}
