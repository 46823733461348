import imgUrl from "../../assets/car.png";

const WizardSummary = () => {
    return (
        <div>
            <img src={imgUrl} alt="Vehicle" className="object-fit h-48" />
        </div>
    );
};

export default WizardSummary;
