// TODO: Remove this file when API is ready
import {FleetOwnerRegistrationService} from "@bolteu/bolt-server-api-fleet-owner-portal";

export type Field = FleetOwnerRegistrationService.FormField;

export interface Step {
    id: string;
    title: string;
    subtitle?: string;
    position: number;
    fields: Field[];
    is_completed: boolean;
}

export interface FormResponse {
    steps: Step[];
}

const steps: Step[] = [
    {
        title: "Condition & milleage",
        is_completed: true,
        id: "condition_mileage",
        position: 0,
        fields: [
            {
                type: FleetOwnerRegistrationService.FormFieldType.CHECKBOXGROUP,
                name: "pricing_model",
                position: 0,
                label: "Pricing Model",
                options: [
                    {label: "Regular rent", value: "regular"},
                    {label: "Rent to buy", value: "rent_to_buy"},
                ],
                values: ["regular"],
                required: true,
                hint: {text: "Select pricing model"},
            },
        ],
    },
    {
        title: "Pricing",
        is_completed: false,
        id: "pricing",
        position: 1,
        fields: [
            {
                type: FleetOwnerRegistrationService.FormFieldType.COMBOBOX,
                name: "pricing_model",
                position: 0,
                label: "Pricing Model",
                options: [
                    {label: "Regular rent", value: "regular"},
                    {label: "Rent to buy", value: "rent_to_buy"},
                ],
                placeholder: "Select pricing model",
                required: true,
            },
            {
                type: FleetOwnerRegistrationService.FormFieldType.COMBOBOX,
                name: "test_multi",
                position: 1,
                label: "Test Multi Combo",
                options: [
                    {label: "Option 1", value: "option1"},
                    {label: "Option 2", value: "option2"},
                ],
                placeholder: "Select test option",
                multi: true,
                values: ["option1"],
                required: true,
            },
            {
                type: FleetOwnerRegistrationService.FormFieldType.INFOTEXT,
                name: "test_1",
                position: 2,
                label: "Test info",
                value: "Test info value",
                required: false,
                hint: {text: "Select pricing model"},
            },
            {
                type: FleetOwnerRegistrationService.FormFieldType.INFOTEXTGROUP,
                name: "test_2",
                position: 2,
                label: "Test info group",
                values: [{value: "Test info value"}, {value: "Test info value2"}],
                required: false,
            },
        ],
        subtitle: "Set pricing for your vehicle",
    },
    {title: "Photos", is_completed: false, id: "photos", position: 2, fields: [], subtitle: ""},
    {
        title: "Driver requirements",
        is_completed: false,
        id: "driver_requirements",
        position: 3,
        fields: [],
        subtitle: "",
    },
];

const FormResponseValue: FormResponse = {steps};
export {FormResponseValue};
