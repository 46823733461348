import {createContext, useCallback, useContext, useEffect, useState} from "react";

import {ApiContextProvider} from "common/services/apiProvider";

import {AccountContextProvider} from "./features/account/accountStateProvider";

export interface Features {
    earning_reports: boolean;
    driver_invitations: boolean;
    campaigns: boolean;
    support_widget: boolean;
    driver_blocks: boolean;
    balance: boolean;
    enterprise_api: boolean;
    vehicle_engagement_data: boolean;
    company_under_review: {
        add_driver: boolean;
        add_vehicle: boolean;
    };
    company_documents: boolean;
    shifts: boolean;
}

const FeaturesContextProvider = createContext<Features | null>(null);
FeaturesContextProvider.displayName = "FeaturesContextProvider";

const defaultFeatures = {
    isEarningReportsEnabled: false,
    isDriverInvitationEnabled: false,
    isCampaignsEnabled: false,
    isSupportWidgetEnabled: false,
    isDriverBlocksEnabled: false,
    isBalanceEnabled: false,
    isEnterpriseApiEnabled: false,
    isVehicleEngagementDataEnabled: false,
    companyUnderReview: {
        isAddVehicleEnabled: false,
        isAddDriverEnabled: false,
    },
    isCompanyDocumentsEnabled: false,
    shifts: false,
};

const FeaturesProvider = ({children}: {children: React.ReactNode}) => {
    const [features, setFeatures] = useState<typeof defaultFeatures | null>(null);
    const api = useContext(ApiContextProvider);
    const accountState = useContext(AccountContextProvider);
    const fleet = accountState.getFleet();

    const getIsAvailableData = useCallback(async () => {
        if (api && fleet) {
            try {
                const response = await api.fleetOwnerPortal.getFeatures();
                setFeatures({
                    isEarningReportsEnabled: response.earnings_dashboard_enabled,
                    isDriverInvitationEnabled: response.driver_invitation_enabled,
                    isCampaignsEnabled: response.incentives_dashboard_enabled,
                    isSupportWidgetEnabled: response.support_widget_enabled,
                    isDriverBlocksEnabled: response.driver_blocks_enabled,
                    isBalanceEnabled: response.balance_page_enabled,
                    isEnterpriseApiEnabled: response.enterprise_api_page_enabled,
                    isVehicleEngagementDataEnabled: response.vehicle_engagement_data_enabled,
                    companyUnderReview: {
                        isAddDriverEnabled: response.company_under_review.add_driver_enabled,
                        isAddVehicleEnabled: response.company_under_review.add_vehicle_enabled,
                    },
                    isCompanyDocumentsEnabled: response.company_documents_enabled,
                    shifts: false,
                });
            } catch (e) {
                setFeatures(defaultFeatures);
            }
        } else {
            setFeatures(null);
        }
    }, [api, fleet]);

    useEffect(() => {
        getIsAvailableData();
    }, [getIsAvailableData]);

    const contextValue: Features | null = features
        ? {
              earning_reports: features.isEarningReportsEnabled,
              driver_invitations: features.isDriverInvitationEnabled,
              campaigns: features.isCampaignsEnabled,
              support_widget: features.isSupportWidgetEnabled,
              driver_blocks: features.isDriverBlocksEnabled,
              balance: features.isBalanceEnabled,
              enterprise_api: features.isEnterpriseApiEnabled,
              vehicle_engagement_data: features.isVehicleEngagementDataEnabled,
              company_under_review: {
                  add_driver: features.companyUnderReview.isAddDriverEnabled,
                  add_vehicle: features.companyUnderReview.isAddVehicleEnabled,
              },
              company_documents: features.isCompanyDocumentsEnabled,
              shifts: features.shifts,
          }
        : null;

    return <FeaturesContextProvider.Provider value={contextValue}>{children}</FeaturesContextProvider.Provider>;
};

export {FeaturesProvider, FeaturesContextProvider};
