import {useCallback, useEffect, useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";

import CloseButton from "common/components/CloseButton";
import {Page} from "common/components/page/Page";
import {useAbsolutePath} from "common/hooks/useAbsolutePath";
import {useI18n} from "common/hooks/useI18n";
import {useTailwindViewport} from "common/hooks/useTailwindViewport";
import {getDocumentTitle} from "common/util/DocumentTitleUtil";
import {FormResponseValue, Step} from "features/companies/vehicleListings/add-listing/mock";

import {LISTING_REVIEW_STEP_ID} from "../utils/constants";
import MobileWizardStepper from "./components/MobileWizardStepper";
import WizardContent, {WizardContenHeader} from "./components/WizardContent";
import WizardReviewContent from "./components/WizardReviewContent";
import WizardStepper from "./components/WizardStepper";
import WizardSummary from "./components/WizardSummary";

interface LayoutProps {
    children: React.ReactNode;
    currentStep?: Step;
    allSteps: Step[];
    onClosePageClick: () => void;
    onStepClick: (step: Step) => void;
}

const DesktopWizardLayout = ({children, currentStep, allSteps, onClosePageClick, onStepClick}: LayoutProps) => {
    return (
        <Page>
            <div className="flex justify-end">
                <CloseButton onClick={onClosePageClick} />
            </div>
            <div className="flex h-[calc(100dvh-14rem)] gap-10">
                <div className="bg-layer-surface basis-3/12 overflow-y-auto rounded-lg px-6 py-4">
                    <div className="flex flex-col gap-4">
                        <WizardSummary />
                        <WizardStepper steps={allSteps} currentStep={currentStep} onClick={onStepClick} />
                    </div>
                </div>
                <div className="basis-9/12">
                    {currentStep && <WizardContenHeader title={currentStep?.title} subtitle={currentStep?.subtitle} />}
                    {children}
                </div>
            </div>
        </Page>
    );
};

const MobileWizardLayout = ({children, currentStep, allSteps, onClosePageClick, onStepClick: _}: LayoutProps) => {
    return (
        <Page>
            <div className="flex justify-end">
                <CloseButton onClick={onClosePageClick} />
            </div>
            <div className="flex flex-col gap-4">
                {currentStep && <WizardContenHeader title={currentStep?.title} subtitle={currentStep?.subtitle} />}
                <MobileWizardStepper steps={allSteps} currentStep={currentStep} />
                {children}
            </div>
        </Page>
    );
};

const AddVehicleListingWizardPage = () => {
    const {i18n} = useI18n();
    const navigate = useNavigate();
    const {getVehiclesPath} = useAbsolutePath();
    const viewport = useTailwindViewport();

    useEffect(() => {
        const pageTitle = i18n("auth.app.fleet.vehicle_marketplace.listings.title");
        document.title = getDocumentTitle(pageTitle);
    }, [i18n]);

    const isMobileView = viewport === "sm";

    const onClosePageClick = useCallback(() => {
        navigate(getVehiclesPath());
    }, [navigate, getVehiclesPath]);

    const {steps} = FormResponseValue;

    const allSteps = useMemo(() => {
        const publishStep = {
            id: LISTING_REVIEW_STEP_ID,
            title: i18n("auth.app.fleet.vehicle_marketplace.listings.add.publish"),
            position: Math.max(...steps.map((x) => x.position)) + 1,
            is_completed: false,
            fields: [],
        };
        return [...steps, publishStep].sort((a, b) => a.position - b.position);
    }, [steps, i18n]);

    const initialCurrentStep = allSteps.find((step) => !step.is_completed);

    const [currentStep, setCurrentStep] = useState<Step | undefined>(initialCurrentStep);

    const onPrevStep = useCallback(() => {
        if (!currentStep) {
            return;
        }
        const prevStep = allSteps.find((step) => step.position === currentStep.position - 1);
        if (!prevStep) {
            return;
        }
        setCurrentStep(prevStep);
    }, [currentStep, allSteps]);

    const onNextStep = useCallback(() => {
        if (!currentStep) {
            return;
        }
        const nextStep = allSteps.find((step) => step.position === currentStep.position + 1);
        if (!nextStep) {
            return;
        }
        currentStep.is_completed = true;
        setCurrentStep(nextStep);
    }, [currentStep, allSteps]);

    const onStep = useCallback(
        (step: Step) => {
            if (step.is_completed) {
                setCurrentStep(step);
            }
        },
        [setCurrentStep],
    );

    const onPublish = useCallback(() => {}, []);

    let WizardRenderContent: React.ReactNode = <></>;
    if (currentStep && currentStep.id !== LISTING_REVIEW_STEP_ID) {
        WizardRenderContent = (
            <WizardContent key={currentStep.id} step={currentStep} onNextStep={onNextStep} onPrevStep={onPrevStep} />
        );
    }

    if (currentStep?.id === LISTING_REVIEW_STEP_ID) {
        WizardRenderContent = (
            <WizardReviewContent
                key={currentStep.id}
                steps={steps}
                onPublishListing={onPublish}
                onPrevStep={onPrevStep}
            />
        );
    }

    const mobileLayout = (
        <MobileWizardLayout
            allSteps={allSteps}
            onClosePageClick={onClosePageClick}
            onStepClick={onStep}
            currentStep={currentStep}
        >
            {WizardRenderContent}
        </MobileWizardLayout>
    );

    return isMobileView ? (
        mobileLayout
    ) : (
        <DesktopWizardLayout
            allSteps={allSteps}
            onClosePageClick={onClosePageClick}
            onStepClick={onStep}
            currentStep={currentStep}
        >
            {WizardRenderContent}
        </DesktopWizardLayout>
    );
};

export default AddVehicleListingWizardPage;
