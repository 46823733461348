import {ChangeEventHandler, useCallback} from "react";

import {useI18n} from "common/hooks/useI18n";

import {GhostButton, Grid, TextField} from "@bolteu/kalep-react";
import {BinOutlined, TimeOutlined} from "@bolteu/kalep-react-icons";

import {ShiftBreakState} from "./ShiftActionDialog";

export interface ShiftBreakProps {
    index: number;
    data: ShiftBreakState;
    isOnlyBreak: boolean;
    handleInputChange: ChangeEventHandler<HTMLInputElement>;
    onDeleteClick: (index: number) => void;
}

const renderTimeIcon = () => <TimeOutlined aria-hidden="true" className="text-content-secondary flex-none" />;

export const BreakRow = ({index, data, isOnlyBreak, handleInputChange, onDeleteClick}: ShiftBreakProps) => {
    const {i18n} = useI18n();

    const onDeleteClickWrapper = useCallback(() => {
        onDeleteClick(index);
    }, [index, onDeleteClick]);

    return (
        <Grid columns={12}>
            <Grid.Item colSpan={6}>
                <div className="flex items-end gap-2">
                    <TextField
                        type="number"
                        placeholder={i18n("common.hour_placeholder")}
                        label={i18n("auth.app.fleet.shifts.shift_form.break_start")}
                        onChange={handleInputChange}
                        data-index={index}
                        data-type="hour"
                        data-name="breakStartHour"
                        value={data.breakStartHour}
                        fullWidth
                    />
                    <div className="mb-3">:</div>
                    <TextField
                        type="number"
                        placeholder={i18n("common.minute_placeholder")}
                        onChange={handleInputChange}
                        data-index={index}
                        data-type="minute"
                        data-name="breakStartMinute"
                        value={data.breakStartMinute}
                        fullWidth
                    />
                </div>
            </Grid.Item>
            <Grid.Item colSpan={6}>
                <div className="flex h-full items-end">
                    <TextField
                        type="number"
                        placeholder="mm"
                        label={i18n("auth.app.fleet.shifts.shift_form.break_duration")}
                        onChange={handleInputChange}
                        data-index={index}
                        data-name="breakDuration"
                        value={data.breakDuration}
                        fullWidth
                        renderStartSlot={renderTimeIcon}
                    />
                    <GhostButton
                        aria-label="Delete break"
                        onClick={onDeleteClickWrapper}
                        overrideClassName="mb-[13px] ml-3"
                        disabled={isOnlyBreak}
                    >
                        <BinOutlined className={isOnlyBreak ? "text-tertiary" : ""} />
                    </GhostButton>
                </div>
            </Grid.Item>
        </Grid>
    );
};
