import {FC, useCallback, useEffect, useMemo, useState} from "react";

import CloseButton from "common/components/CloseButton";
import {ResponsiveHeader, ResponsiveHeaderType} from "common/components/header/ResponsiveHeader";
import {Page} from "common/components/page/Page";
import Pagination from "common/components/Pagination";
import TableSearchField from "common/components/table/TableSearchField";
import {EventName, EventPropertiesFormSubmissionFailedName} from "common/constants/events";
import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {useTracking} from "common/hooks/useTracking";
import {Api} from "common/services/apiProvider";

import {FleetShiftManagementService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Button} from "@bolteu/kalep-react";

import ConfirmationDialog from "./ConfirmationDialog";
import ShiftAssignmentsTable, {ShiftTableData} from "./ShiftAssignmentsTable";
import DriverShiftAssignmentRequest = FleetShiftManagementService.DriverShiftAssignmentRequest;
import DriverShiftAssignmentUpdate = FleetShiftManagementService.DriverShiftAssignmentUpdate;

export interface ManageShiftAssignmentsProps {
    onAssignmentsUpdated: () => void;
    closePage: () => void;
}

export interface ShiftAssignment {
    driver_id: number;
    shift_id: number | null;
    vehicle_id: number | null;
}

const MAX_PAGE_SIZE = 50;

const getDriverAssignmentsFetchFunction = (api: Api) => api.fleetShiftManagement.getDriverAssignmentData({});
const saveDriverAssignmentsFetchFunction = (api: Api, body: DriverShiftAssignmentRequest) =>
    api.fleetShiftManagement.saveDriverShiftAssignments(body);

const ManageShiftAssignmentsPage: FC<ManageShiftAssignmentsProps> = ({onAssignmentsUpdated, closePage}) => {
    const {trackEvent} = useTracking();
    const {i18n} = useI18n();
    const [currentPage, setCurrentPage] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [searchValue, setSearchValue] = useState("");
    const [filteredAssignments, setFilteredAssignments] = useState<ShiftTableData[]>([]);
    const [updatedAssignments, setUpdatedAssignments] = useState<Map<number, DriverShiftAssignmentUpdate>>(new Map());
    const [isConfirmationDialogVisible, setConfirmationDialogVisible] = useState(false);
    const [isExitingDialog, setIsExitingDialog] = useState(false);

    const {
        data: driverAssignments,
        fetch: fetchDriverAssignments,
        status: getDriverAssignmentsStatus,
    } = useFetch(getDriverAssignmentsFetchFunction);
    const {
        fetch: saveDriverAssignments,
        status: saveDriverAssignmentsStatus,
        error: saveDriverAssignmentsError,
    } = useFetch(saveDriverAssignmentsFetchFunction);

    const isLoading = [FetchStatus.Loading, FetchStatus.Init].includes(getDriverAssignmentsStatus);

    const allDriverAssignments = useMemo((): ShiftTableData[] => {
        if (!driverAssignments) {
            return [];
        }
        setTotalCount(driverAssignments.drivers.length);
        return driverAssignments.drivers.map(({driver_id, ...rest}) => ({
            id: driver_id,
            ...rest,
        }));
    }, [driverAssignments]);

    const paginatedDriverAssignments = useMemo(() => {
        const start = currentPage * MAX_PAGE_SIZE;
        return filteredAssignments.slice(start, start + MAX_PAGE_SIZE);
    }, [filteredAssignments, currentPage]);

    const handlePageChange = useCallback((page: number) => setCurrentPage(page), []);

    const handleAssignmentChange = useCallback((driverId: number, newData: Partial<ShiftAssignment>) => {
        setUpdatedAssignments((currentAssignments) => {
            const newAssignments = new Map(currentAssignments);
            const existingAssignment = newAssignments.get(driverId) || {
                driver_id: driverId,
                vehicle_id: null,
                shift_id: null,
            };
            const updatedAssignment = {...existingAssignment, ...newData};
            newAssignments.set(driverId, updatedAssignment);
            return newAssignments;
        });
    }, []);

    const saveAssignments = useCallback(() => {
        if (saveDriverAssignments && updatedAssignments.size > 0) {
            const assignmentsList = Array.from(updatedAssignments.values());
            saveDriverAssignments({list: assignmentsList});
        }
    }, [saveDriverAssignments, updatedAssignments]);

    const openConfirmationDialog = useCallback(() => {
        setConfirmationDialogVisible(true);
    }, []);

    const closeConfirmationDialog = useCallback(() => {
        setConfirmationDialogVisible(false);
    }, []);

    const closeAndExitConfirmationDialog = useCallback(() => {
        setConfirmationDialogVisible(false);
        closePage();
    }, [closePage]);

    const closeShiftAssignmentsPage = useCallback(() => {
        if (updatedAssignments.size > 0) {
            setIsExitingDialog(true);
            setConfirmationDialogVisible(true);
        } else {
            closePage();
        }
    }, [closePage, updatedAssignments.size]);

    // Display a popup warning if the user has unsaved changes and attempts to refresh or close the window
    useEffect(() => {
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            if (updatedAssignments.size > 0) {
                event.preventDefault();
                if (event) {
                    // eslint-disable-next-line no-param-reassign -- allow reassign
                    event.returnValue = "";
                }
                return "";
            }
            return undefined;
        };

        window.addEventListener("beforeunload", handleBeforeUnload);
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, [updatedAssignments]);

    useEffect(() => {
        if (fetchDriverAssignments) {
            fetchDriverAssignments({});
        }
    }, [fetchDriverAssignments]);

    useEffect(() => {
        const searchTerm = searchValue.toLowerCase();
        const filtered = allDriverAssignments.filter((d) => d.driver_name.toLowerCase().includes(searchTerm));
        setFilteredAssignments(filtered);
        setTotalCount(filtered.length);
    }, [searchValue, allDriverAssignments]);

    useEffect(() => {
        if (saveDriverAssignmentsStatus === FetchStatus.Success) {
            trackEvent(EventName.AssignShiftsSuccessful);
            setConfirmationDialogVisible(false);
            onAssignmentsUpdated();
            closePage();
        } else if (saveDriverAssignmentsStatus === FetchStatus.Error && saveDriverAssignmentsError) {
            trackEvent(EventName.FormSubmissionFailed, {
                formName: EventPropertiesFormSubmissionFailedName.AssingShifts,
                errorMessage: saveDriverAssignmentsError.message,
            });
        }
    }, [closePage, onAssignmentsUpdated, saveDriverAssignmentsError, saveDriverAssignmentsStatus, trackEvent]);

    return (
        <Page className="max-w-6xl">
            <div className="flex justify-end">
                <CloseButton onClick={closeShiftAssignmentsPage} />
            </div>
            <div className="my-6 flex flex-col gap-3">
                <ResponsiveHeader
                    type={ResponsiveHeaderType.InnerPage}
                    text={i18n("auth.app.fleet.shifts.assign_shift.title")}
                />
                <div className="max-w-[500px] font-normal text-neutral-700">
                    {i18n("auth.app.fleet.shifts.assign_shift.description")}
                </div>
            </div>
            <div className="flex gap-4 lg:flex-row lg:justify-between">
                <TableSearchField onChange={setSearchValue} />
            </div>
            <ShiftAssignmentsTable
                vehicles={driverAssignments?.vehicles}
                shifts={driverAssignments?.shifts}
                tableData={paginatedDriverAssignments}
                updatedAssignments={updatedAssignments}
                onAssignmentChange={handleAssignmentChange}
                isLoading={isLoading}
            />
            <Pagination
                pageSize={MAX_PAGE_SIZE}
                totalCount={totalCount}
                currentPage={currentPage}
                onPageChange={handlePageChange}
                disabled={isLoading}
                showText
            />
            {totalCount > 0 && (
                <div className="mt-6">
                    <Button variant="primary" onClick={openConfirmationDialog} disabled={updatedAssignments.size === 0}>
                        {i18n("common.submit")}
                    </Button>
                </div>
            )}
            <ConfirmationDialog
                confirm={saveAssignments}
                close={closeConfirmationDialog}
                isExitingDialog={isExitingDialog}
                show={isConfirmationDialogVisible}
                closeAndExit={closeAndExitConfirmationDialog}
            />
        </Page>
    );
};

export default ManageShiftAssignmentsPage;
