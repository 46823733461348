/**
 *! If you add a new route to the enums below, then also add a new entry in pages and
 *! update routeUtils.getPageNameFromRoute
 */
export enum CommonRoute {
    AUTH = "/login",
    FORGOT_PASSWORD = "/forgotPassword",
    RESET_PASSWORD = "/resetPassword/:token",
    HOME = "/",
    INVOICES = "invoices",
    SETTINGS = "settings",
    VERIFICATION = "/verification/:key",
    VERIFICATION_PASSWORD = "/verification/password/:key",
    VERIFICATION_CONFIRMATION = "/verification/confirmation/:key",
    VERIFICATION_2FA = "/verification/2fa/:key",
    VERIFICATION_2FA_CONFIRMATION = "/verification/2fa/confirmation/:key",
    ADD_COMPANY = "/company/add",
    REGISTER_COMPANY = "/company/add/:hash",
    ONBOARD_COMPANY = "/onboarding/:companyId",
    SMS_NOTIFICATION = "/sms/:id",
}

export enum FleetRoute {
    CAR_RENTAL_PAYMENT = "carRentalPayment",
    COMPANY_SETTINGS = "companySettings",
    DRIVER = "drivers/:id",
    DRIVERS = "drivers",
    DRIVER_REGISTRATION = "drivers/registration/:id",
    DRIVER_INVITATION = "drivers/invitation/:id",
    DRIVER_REGISTRATIONS = "drivers?tab=registrations",
    EXPIRING_DOCUMENTS = "expiringDocuments",
    LIVE_MAP = "liveMap",
    ORDERS = "orders",
    REPORTS = "reports",
    VEHICLE = "vehicles/:id",
    VEHICLES = "vehicles",
    VEHICLE_APPLICATIONS = "vehicles?tab=applications",
    VEHICLE_APPLICATION = "vehicles/applications/:id",
    CAMPAIGNS = "campaigns",
    CAMPAIGN_DETAILS = "campaigns/:type/:id",
    BALANCE = "balance",
    NOTIFICATIONS = "notifications",
    SUPPORT = "support",
    SHIFTS = "shifts",
    VEHICLE_LISTINGS = "vehicles/vehicle_listings",
}

export enum HoldingFleetRoute {}

export const SmsNotificationRoutePrefix = "sms";
export const HoldingFleetRoutePrefix = "enterprise";
export const ResetPasswordPrefix = "resetPassword";
export const VerificationPrefix = "verification";
export const CompanyRegistrationPrefix = "company/add";
export const VerificationKeyMinLength = 40;
export const CompanyOnboardingRoutePrefix = "onboarding";
export const DeepLinkPrefix = "l/";
