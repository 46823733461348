import {useCallback, useRef, useState} from "react";

import {BBox, GoogleMapApi, Map, Maps, Ref} from "common/geo/components/google-maps/types";

import {FleetOwnerPortalService, OrderFleetService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import {DriverInfo} from "../../types";
import {useCalculateDriverDirection} from "./useCalculateDriverDirection";
import {useDriverLocationHistory} from "./useDriverLocationHistory";
import useDriverMapMarkers from "./useDriverMapMarkers";
import {useUserMapChange} from "./useUserMapChange";
import {useZoomLevel} from "./useZoomLevel";

export function useMapView(
    drivers: FleetOwnerPortalService.DriverForFleetOwner[],
    selectedDriver: DriverInfo | null,
    previewDriverId: number | null,
    isMobileView: boolean,
    isMapView: boolean,
    lastDropoffAndWaitingTime: OrderFleetService.GetLastDropoffAndWaitingTimeResponse | null,
    isShowDriverRoutesEnabled: boolean,
    driverTrailPeriodMs: number,
    onDriverSelected: (driver: FleetOwnerPortalService.DriverForFleetOwner) => void,
    onDriverPreviewSelected?: (driverId: number | null) => void,
) {
    // Loosely center the Europe
    const defaultMapConfig = {
        center: {
            lat: 48,
            lng: 13,
        },
        zoom: 4,
    };

    const [googleMapsApi, setGoogleMapsApi] = useState<GoogleMapApi | undefined>();
    const [zoom, setZoom] = useState(defaultMapConfig.zoom);
    const [bounds, setBounds] = useState<BBox>([
        -84.822265625, 15.129091742705643, 110.82226562499999, 68.20980341203649,
    ]);

    const automaticZoomLevel = useRef<number>();

    const getBbox = useCallback((): BBox => {
        const mapBounds = googleMapsApi?.map.getBounds();
        if (!mapBounds) {
            return [0, 0, 0, 0];
        }
        const sw = mapBounds.getSouthWest();
        const ne = mapBounds.getNorthEast();
        return [sw.lng(), sw.lat(), ne.lng(), ne.lat()];
    }, [googleMapsApi?.map]);

    const selectDriverPreview = useCallback(
        (driverId: number | null) => {
            if (onDriverPreviewSelected !== undefined) {
                onDriverPreviewSelected(driverId);
            }
        },
        [onDriverPreviewSelected],
    );

    const onGoogleApiLoaded = useCallback(({map, maps, ref}: {map: Map; maps: Maps; ref: Ref}) => {
        setGoogleMapsApi({
            map,
            maps,
            ref,
        });
    }, []);

    const {handleUserChangedMap, hasUserRecentlyChangedMap} = useUserMapChange();
    const driverLocationHistory = useDriverLocationHistory(
        drivers,
        selectedDriver,
        isShowDriverRoutesEnabled,
        driverTrailPeriodMs,
    );
    const driverDirections = useCalculateDriverDirection(driverLocationHistory);

    const markers = useDriverMapMarkers(
        drivers,
        driverLocationHistory,
        selectedDriver,
        driverDirections,
        previewDriverId,
        isMobileView,
        lastDropoffAndWaitingTime,
        zoom,
        bounds,
        selectDriverPreview,
        onDriverSelected,
    );

    const zoomLevel = useZoomLevel(
        googleMapsApi,
        drivers,
        hasUserRecentlyChangedMap,
        isMobileView,
        selectedDriver,
        lastDropoffAndWaitingTime,
        isMapView,
        setBounds,
        getBbox,
    );

    const handleZoomEnd = useCallback(
        (zoomValue: number) => {
            setZoom(zoomValue);
            setBounds(getBbox());
            if (zoomValue !== automaticZoomLevel.current) {
                handleUserChangedMap();
            }
        },
        [getBbox, handleUserChangedMap, automaticZoomLevel, setBounds],
    );

    const handleOnDrag = useCallback(() => {
        setBounds(getBbox());
        handleUserChangedMap();
    }, [getBbox, handleUserChangedMap, setBounds]);

    automaticZoomLevel.current = zoomLevel;

    return {
        defaultMapConfig,
        hasUserRecentlyChangedMap,
        googleMapsApi,
        markers,
        automaticZoomLevel,
        setBounds,
        getBbox,
        onGoogleApiLoaded,
        handleZoomEnd,
        handleOnDrag,
        driverLocationHistory,
    };
}
