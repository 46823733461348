import {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";

import CloseButton from "common/components/CloseButton";
import {ResponsiveHeader, ResponsiveHeaderType} from "common/components/header/ResponsiveHeader";
import LoadingSpinner from "common/components/spinner";
import {useAbsolutePath} from "common/hooks/useAbsolutePath";
import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {Api} from "common/services/apiProvider";
import {getDocumentTitle} from "common/util/DocumentTitleUtil";
import {TranslationKeys} from "config/translations";
import {AccountContextProvider} from "features/account/accountStateProvider";
import {
    AddVehiclePageContextProvider,
    AddVehiclePageProvider,
} from "features/companies/vehicles/list-view/components/AddVehiclePageProvider";

import {FormChange, FormState, useForm} from "@bolteu/kalep-form-react";
import {Grid, SelectOption} from "@bolteu/kalep-react";
import {Plus} from "@bolteu/kalep-react-icons";

interface VehicleListingCreateForm {
    model_id: number | null;
    years: number[];
    colors: string[];
}

const getVehicleModelsFetchFunction = (api: Api) => api.fleetOwnerPortal.vehicleModelsList({});

const AddVehicleListingPage = () => {
    const {isAddVehiclePageOpen, openAddVehiclePage} = useContext(AddVehiclePageContextProvider);
    const {getVehiclesPath} = useAbsolutePath();
    const {i18n} = useI18n();

    useEffect(() => {
        const pageTitle = i18n("auth.app.fleet.vehicle_marketplace.listings.title");
        document.title = getDocumentTitle(pageTitle);
    }, [i18n]);

    const navigate = useNavigate();
    const accountState = useContext(AccountContextProvider);
    const fleet = accountState?.getFleet();
    const {
        data: vehicleModelsData,
        fetch: fetchVehicleModels,
        status: fetchVehicleModelsStatus,
    } = useFetch(getVehicleModelsFetchFunction);

    useEffect(() => {
        if (fetchVehicleModels && fleet) {
            fetchVehicleModels({});
        }
    }, [fetchVehicleModels, fleet]);

    const [vehicleColors, setVehicleColors] = useState<string[]>([]);
    const [years, setYears] = useState<number[]>([]);

    const onClosePageClick = useCallback(() => {
        navigate(getVehiclesPath());
    }, [navigate, getVehiclesPath]);

    const defaultFormValues: VehicleListingCreateForm = {
        model_id: null,
        colors: [],
        years: [],
    };

    const {Form, SelectField, setData, AutocompleteField, Button} = useForm(defaultFormValues);

    const colors = () => {
        return vehicleColors.map((c: string) => {
            return {value: c, title: i18n(`vehicle.color.${c}` as TranslationKeys)} as SelectOption;
        });
    };

    const yearSeleсtions = () => {
        return years.sort().map((year: number) => {
            return {value: year} as SelectOption;
        });
    };

    const onFormChange = useCallback(
        (change: FormChange<VehicleListingCreateForm>) => {
            if (change.event.name === "model_id") {
                const modelId = change.event.value as number;
                const model = vehicleModelsData?.list.find((m) => m.model_id === modelId);

                setData({colors: [], years: []});
                setVehicleColors(model?.colors || []);
                setYears(model?.years || []);
            }
        },
        [setData, setVehicleColors, vehicleModelsData],
    );

    // TODO: implement onSubmitForm when API is ready
    const onSubmitForm = useCallback(async (_: FormState<VehicleListingCreateForm>) => {}, []);

    const vehicleModels = useMemo(() => {
        return (vehicleModelsData?.list || [])
            .sort((a, b) => b.total_number - a.total_number)
            .map((model) => {
                return {
                    value: model.model_id,
                    title: model.model,
                    secondary: i18n("auth.app.fleet.vehicle_marketplace.listings.add.amount_of_models", {
                        total: model.total_number,
                    }),
                } as SelectOption;
            });
    }, [vehicleModelsData, i18n]);

    if (fetchVehicleModelsStatus === FetchStatus.Loading) {
        return <LoadingSpinner show size="lg" showLoadingText parentClassName="mt-9" />;
    }

    if (isAddVehiclePageOpen) {
        return null;
    }

    return (
        <Form onChange={onFormChange} onSubmit={onSubmitForm}>
            <div className="m-auto my-10 flex w-[484px] max-w-3xl flex-col justify-center">
                <div className="flex justify-end">
                    <CloseButton onClick={onClosePageClick} />
                </div>
                <div className="my-6 flex flex-col gap-3">
                    <ResponsiveHeader
                        type={ResponsiveHeaderType.InnerPage}
                        text={i18n("auth.app.fleet.vehicle_marketplace.listings.add.title")}
                    />
                </div>
                <p>{i18n("auth.app.fleet.vehicle_marketplace.listings.add.description")}</p>
                <p className="my-2">
                    <Button variant="tertiary" startIcon={<Plus />} onClick={openAddVehiclePage}>
                        {i18n("auth.app.fleet.add-vehicle.title")}
                    </Button>
                </p>
                <Grid columns={1}>
                    <SelectField
                        name="model_id"
                        label={i18n("auth.app.fleet.vehicle_marketplace.listings.add.select_model_title")}
                        placeholder={i18n("auth.app.fleet.vehicle_marketplace.listings.add.select_model")}
                        options={vehicleModels}
                        required
                        fullWidth
                    />
                    <AutocompleteField
                        name="years"
                        label={i18n("auth.app.fleet.add-vehicle.form.year")}
                        placeholder={i18n("auth.app.fleet.add-vehicle.form.year")}
                        options={yearSeleсtions()}
                        multiple
                        disabled={years.length === 0}
                        required
                    />
                    <AutocompleteField
                        name="colors"
                        label={i18n("auth.app.fleet.add-vehicle.form.color")}
                        placeholder={i18n("auth.app.fleet.add-vehicle.form.color")}
                        options={colors()}
                        multiple
                        disabled={vehicleColors.length === 0}
                        required
                    />
                </Grid>
                <div className="mt-6">
                    <Button submit variant="primary">
                        {i18n("form.button.next")}
                    </Button>
                </div>
            </div>
        </Form>
    );
};

const AddVehicleListingPageWithProvider = () => {
    return (
        <AddVehiclePageProvider>
            <AddVehicleListingPage />
        </AddVehiclePageProvider>
    );
};

export default AddVehicleListingPageWithProvider;
