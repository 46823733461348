import {useCallback, useMemo} from "react";

import {useI18n} from "common/hooks/useI18n";

import {FleetShiftManagementService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {ComboBox, SelectOption} from "@bolteu/kalep-react";

import {ShiftAssignment} from "../components/ManageShiftAssignmentsPage";
import {ShiftTableData} from "../components/ShiftAssignmentsTable";
import AssigmentVehicle = FleetShiftManagementService.AssigmentVehicle;
import AssigmentShift = FleetShiftManagementService.AssigmentShift;

export const useTableColumns = (
    tableData: ShiftTableData[],
    vehicles: AssigmentVehicle[] | undefined,
    shifts: AssigmentShift[] | undefined,
    updatedAssignments: Map<number, ShiftAssignment>,
    onAssignmentChange: (id: number, updatedAssignment: Partial<ShiftAssignment>) => void,
) => {
    const {i18n} = useI18n();

    const getVehicleSelectOptions = useMemo(
        () =>
            vehicles?.map((v) => ({
                title: `${v.model}, ${v.year}`,
                secondary: `${v.reg_number} • ${v.color}`,
                value: v.id,
            })) || [],
        [vehicles],
    );

    const getShiftSelectOptions = useMemo(
        () =>
            shifts?.map((s) => ({
                title: s.name,
                secondary: `${s.start_time} - ${s.end_time}`,
                value: s.id,
            })) || [],
        [shifts],
    );

    const getVehicleSelectValue = useCallback(
        (vehicleId: number | null) =>
            vehicleId ? getVehicleSelectOptions.find((option) => option.value === vehicleId) ?? null : null,
        [getVehicleSelectOptions],
    );

    const getShiftSelectValue = useCallback(
        (shiftId: number | null) =>
            shiftId ? getShiftSelectOptions.find((option) => option.value === shiftId) ?? null : null,
        [getShiftSelectOptions],
    );

    const handleVehicleChange = useCallback(
        (driverId: number) => (newValue: SelectOption | SelectOption[] | null) => {
            if (!Array.isArray(newValue)) {
                const currentAssignment =
                    updatedAssignments.get(driverId) || tableData.find((data) => data.id === driverId);

                onAssignmentChange(driverId, {
                    vehicle_id: newValue ? Number(newValue.value) : null,
                    shift_id: currentAssignment?.shift_id ?? null,
                });
            }
        },
        [onAssignmentChange, updatedAssignments, tableData],
    );

    const handleShiftChange = useCallback(
        (driverId: number) => (newValue: SelectOption | SelectOption[] | null) => {
            if (!Array.isArray(newValue)) {
                const currentAssignment =
                    updatedAssignments.get(driverId) || tableData.find((data) => data.id === driverId);

                onAssignmentChange(driverId, {
                    shift_id: newValue ? Number(newValue.value) : null,
                    vehicle_id: currentAssignment?.vehicle_id ?? null,
                });
            }
        },
        [onAssignmentChange, updatedAssignments, tableData],
    );

    const renderComboBox = ({
        value,
        options,
        onChange,
        placeholder,
        fullWidth,
    }: {
        value: SelectOption | null;
        options: SelectOption[];
        onChange: (newValue: SelectOption | SelectOption[] | null) => void;
        placeholder: string;
        fullWidth: boolean;
    }) => (
        <div className="p-3">
            <ComboBox
                value={value}
                options={options}
                onChange={onChange}
                placeholder={placeholder}
                fullWidth={fullWidth}
                clearable
            />
        </div>
    );

    const tableColumns = useMemo(() => {
        return {
            driver_name: {
                label: i18n("auth.app.fleet.shifts.assign_shift.driver"),
                sortable: true,
            },
            vehicle_id: {
                label: i18n("auth.app.fleet.shifts.assign_shift.vehicle"),
                renderCell: (item: ShiftTableData) =>
                    renderComboBox({
                        value: getVehicleSelectValue(item.vehicle_id),
                        options: getVehicleSelectOptions,
                        onChange: handleVehicleChange(item.id),
                        placeholder: i18n("auth.app.fleet.shifts.assign_shift.select_vehicle"),
                        fullWidth: false,
                    }),
            },
            shift_id: {
                label: i18n("auth.app.fleet.shifts.assign_shift.shift"),
                renderCell: (item: ShiftTableData) =>
                    renderComboBox({
                        value: getShiftSelectValue(item.shift_id),
                        options: getShiftSelectOptions,
                        onChange: handleShiftChange(item.id),
                        placeholder: i18n("auth.app.fleet.shifts.assign_shift.select_shift"),
                        fullWidth: false,
                    }),
            },
        };
    }, [
        getVehicleSelectValue,
        getVehicleSelectOptions,
        getShiftSelectValue,
        getShiftSelectOptions,
        handleVehicleChange,
        handleShiftChange,
        i18n,
    ]);

    const accordionTableColumns = useMemo(() => {
        return {
            vehicle_id: {
                renderCell: (item: ShiftTableData) =>
                    renderComboBox({
                        value: getVehicleSelectValue(item.vehicle_id),
                        options: getVehicleSelectOptions,
                        onChange: handleVehicleChange(item.id),
                        placeholder: i18n("auth.app.fleet.shifts.assign_shift.select_vehicle"),
                        fullWidth: true,
                    }),
            },
            shift_id: {
                renderCell: (item: ShiftTableData) =>
                    renderComboBox({
                        value: getShiftSelectValue(item.shift_id),
                        options: getShiftSelectOptions,
                        onChange: handleShiftChange(item.id),
                        placeholder: i18n("auth.app.fleet.shifts.assign_shift.select_shift"),
                        fullWidth: true,
                    }),
            },
        };
    }, [
        getVehicleSelectValue,
        getVehicleSelectOptions,
        getShiftSelectValue,
        getShiftSelectOptions,
        handleVehicleChange,
        handleShiftChange,
        i18n,
    ]);

    return {tableColumns, accordionTableColumns};
};
