import {useCallback, useContext, useEffect, useState} from "react";

import ErrorView, {ErrorViewType} from "common/components/error/ErrorView";
import {Page} from "common/components/page/Page";
import {useTabs} from "common/components/tab/useTabs";
import {FetchStatus, UseFetch, useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {Api} from "common/services/apiProvider";
import {ApiPeriod, apiPeriodToDates, OngoingDay, Period} from "common/services/period";
import {getDocumentTitle} from "common/util/DocumentTitleUtil";
import {getIsoDate} from "common/util/FormatUtil";
import {AccountContextProvider} from "features/account/accountStateProvider";

import {CarApplicationService, FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import {FeaturesContextProvider} from "../../../../FeaturesProvider";
import {AddVehiclePageContextProvider, AddVehiclePageProvider} from "./components/AddVehiclePageProvider";
import ApplicationsComponent from "./components/ApplicationsTable";
import {defaultVehicleFilters, VehicleFilter} from "./components/VehiclesFilter";
import VehiclesPageHeader from "./components/VehiclesPageHeader";
import VehiclesTableV2 from "./components/VehiclesTableV2";
import {useTabSelectorOptions} from "./hooks/useTabSelectorOptions";

const MAX_LIMIT = 25;

export enum VehicleTabs {
    Vehicles = "vehicles",
    Applications = "applications",
}

const getVehicleColumnsFetchFunction = (api: Api, body: FleetOwnerPortalService.GetCarsRequest) =>
    api.fleetOwnerPortal.getVehicles(body);

const getCarApplicationsFetchFunction = (api: Api) => api.carApplication.v2GetCarApplications({});

interface VehiclesPageProps {
    useCarApplicationsFetch: UseFetch<unknown, CarApplicationService.GetCarApplicationsResponse>;
}

const VehiclesPage = ({useCarApplicationsFetch}: VehiclesPageProps) => {
    const {i18n} = useI18n();
    const fleet = useContext(AccountContextProvider)?.getFleet();
    const features = useContext(FeaturesContextProvider);
    const {isAddVehiclePageOpen, openAddVehiclePage} = useContext(AddVehiclePageContextProvider);

    const [pageNum, setPageNum] = useState(0);
    const [tableSearchValue, setTableSearchValue] = useState("");
    const [vehiclesFilters, setVehiclesFilters] = useState<VehicleFilter>(defaultVehicleFilters);
    const [selectedPeriod, setSelectedPeriod] = useState<ApiPeriod>({period: Period.ONGOING_DAY} as OngoingDay);

    const {
        data: vehicleColumns,
        fetch: vehicleColumnsFetch,
        status: getVehicleColumnsStatus,
    } = useFetch(getVehicleColumnsFetchFunction);

    const {data: applications, fetch: carApplicationsFetch, status: applicationsStatus} = useCarApplicationsFetch;

    const vehiclesCount = vehicleColumns?.total_rows ?? 0;
    const applicationsCount = applications?.list.length ?? 0;

    const isLoading = [FetchStatus.Init, FetchStatus.Loading].includes(getVehicleColumnsStatus);
    const tabs = useTabSelectorOptions(vehiclesCount, applicationsCount);

    const shouldShowApplications = !vehiclesCount && !tableSearchValue;
    const {activeTab, TabSelector} = useTabs(
        tabs,
        shouldShowApplications ? VehicleTabs.Applications : VehicleTabs.Vehicles,
    );

    const calculateThirtyDaysAgo = () => {
        const today = new Date();
        today.setDate(today.getDate() - 30);
        return today;
    };

    const getRequestDates = useCallback(
        (period: ApiPeriod) => {
            if (!features?.vehicle_engagement_data) {
                return {start: calculateThirtyDaysAgo(), end: new Date()};
            }

            return period.period === Period.CUSTOM
                ? {start: period.startDate, end: period.endDate}
                : apiPeriodToDates(period);
        },
        [features?.vehicle_engagement_data],
    );

    const getVehiclesColumnData = useCallback(() => {
        if (vehicleColumnsFetch) {
            const {start, end} = getRequestDates(selectedPeriod);
            const offset = pageNum * MAX_LIMIT;
            vehicleColumnsFetch({
                offset,
                limit: MAX_LIMIT,
                start_date: getIsoDate(start),
                end_date: getIsoDate(end),
                portal_status: vehiclesFilters.status ?? undefined,
                ...(tableSearchValue && {search: tableSearchValue}),
            });
        }
    }, [pageNum, vehicleColumnsFetch, getRequestDates, selectedPeriod, vehiclesFilters, tableSearchValue]);

    useEffect(() => {
        if (features?.vehicle_engagement_data !== undefined) {
            getVehiclesColumnData();
        }
    }, [features?.vehicle_engagement_data, getVehiclesColumnData]);

    useEffect(() => {
        const pageTitle = i18n("auth.app.fleet.vehicles.title");
        document.title = getDocumentTitle(pageTitle);
    }, [i18n]);

    useEffect(() => {
        if (carApplicationsFetch && fleet) {
            carApplicationsFetch({});
        }
    }, [carApplicationsFetch, fleet]);

    if (getVehicleColumnsStatus === FetchStatus.Error || applicationsStatus === FetchStatus.Error) {
        return <ErrorView type={ErrorViewType.SomethingWentWrong} />;
    }

    if (isAddVehiclePageOpen) {
        return null;
    }

    return (
        <Page>
            <VehiclesPageHeader openAddVehiclePage={openAddVehiclePage} tabSelectorComponent={TabSelector} />
            {activeTab === VehicleTabs.Vehicles && (
                <VehiclesTableV2
                    columnsResponse={vehicleColumns}
                    vehiclesCount={vehiclesCount}
                    currentPage={pageNum}
                    pageSize={MAX_LIMIT}
                    onPageChange={setPageNum}
                    filters={vehiclesFilters}
                    onPeriodChange={setSelectedPeriod}
                    onSearchChange={setTableSearchValue}
                    openAddVehiclePage={openAddVehiclePage}
                    selectedPeriod={selectedPeriod}
                    setFilters={setVehiclesFilters}
                    isLoading={isLoading}
                />
            )}
            {activeTab === VehicleTabs.Applications && (
                <ApplicationsComponent applications={applications?.list || []} />
            )}
        </Page>
    );
};

const VehiclesPageWithProvider = () => {
    const fleet = useContext(AccountContextProvider)?.getFleet();
    const useCarApplicationsFetch = useFetch(getCarApplicationsFetchFunction);

    const {fetch: carApplicationsFetch} = useCarApplicationsFetch;

    const onVehicleAdded = useCallback(async () => {
        if (carApplicationsFetch && fleet) {
            return carApplicationsFetch({});
        }
        return Promise.resolve();
    }, [carApplicationsFetch, fleet]);

    return (
        <AddVehiclePageProvider onVehicleAdded={onVehicleAdded}>
            <VehiclesPage useCarApplicationsFetch={useCarApplicationsFetch} />
        </AddVehiclePageProvider>
    );
};

export default VehiclesPageWithProvider;
